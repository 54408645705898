<!--运力 服务商列表 服务商详情 系统设置 订单分配 订单分配设置-->
<template>
  <div>
    <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单分配设置':'订单分配设置'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        label-width="80px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label='部门机构:'>
              <div class="divkuai">
                <el-select v-model="form.department" placeholder="请选择部门机构">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-select class="margin-l" v-model="form.department1" placeholder="选择组">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label='启用:'>
              <el-switch v-model="form.value"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label='救援类型:'>
              <el-radio v-model="radio" label="1">事故救援</el-radio>
              <el-radio v-model="radio" label="2">高速救援</el-radio>
              <el-radio v-model="radio" label="3">非事故救援</el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="18">
            <div class="pricingRules">
              <div class="pricing" v-for="(item,index) in dataList" :key="index">
                <div class="pricingRules_t_bg">
                  <el-checkbox v-model="item.switch"></el-checkbox>
                  <span class="font1">{{item.title}}</span>
                </div>
                <div v-if="item.switch == true"  class="padding-bianju">
                  <div v-for="(it,idx) in item.data" :key="idx" class="margin-bianju">
                    <div>
                      <el-checkbox v-model="it.switch"></el-checkbox>
                      <span class="font1">{{it.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="provinceAndCity">
              <div class="provinceAndCity_t">
                <span style="vertical-align: middle">区域：</span>
                <el-checkbox v-model="selectAll" @change="handleCheckAllChange">全选</el-checkbox>
              </div>
              <!--省市区-->
              <div class="provinceAndCity_b">
                <!--@check="treeCheck"  实现单选-->
                <el-tree
                  :data="city"
                  ref="tree"
                  show-checkbox
                  node-key="value"
                  :default-checked-keys="[keys]"
                  @check-change="handleCheckChange"
                  :props="defaultProps">
                </el-tree>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <div class="fenpeistyle">
            <div class="fenpeistyle-s">
              <el-checkbox v-model="checked">小组A</el-checkbox>
              <el-input placeholder="分配比例 %" v-model="input" clearable class="fenpeistyle-s-input">
                <template slot="append">%</template>
              </el-input>
            </div>
            <div class="fenpeistyle-s">
              <el-checkbox v-model="checked1">小组B</el-checkbox>
              <el-input placeholder="分配比例 %" v-model="input1" clearable class="fenpeistyle-s-input">
                <template slot="append">%</template>
              </el-input>
            </div>
            <div class="fenpeistyle-s">
              <el-checkbox v-model="checked2">小组C</el-checkbox>
              <el-input placeholder="分配比例 %" v-model="input2" clearable class="fenpeistyle-s-input">
                <template slot="append">%</template>
              </el-input>
            </div>
            <div class="fenpeistyle-s">
              所有小组分配比例之和必须为 100
            </div>
          </div>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
//引入的接口
import {
  taximeterget_city
} from '@/api/yunli'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {

  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      options: [
        {
          value: '1',
          label: '调度'
        },
        {
          value: '2',
          label: '调度1'
        }
      ],
      options1: [
        {
          value: '1',
          label: '调度A组'
        },
        {
          value: '2',
          label: '调度B组'
        },
        {
          value: '3',
          label: '调度C组'
        },
        {
          value: '4',
          label: '调度D组'
        }
      ],

      radio:'',

      // 省市区
      //城市列表
      city:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //默认选中的节点
      keys:[],
      selectList:[],
      // 区域 全选按钮
      selectAll:false,

      dataList:[
        {
          switch:false,
          title:'拖车',
          data:[
            {
              switch:false,
              title:'小型车(5吨以内)'
            },
            {
              switch:false,
              title:'客车、货车(5吨-10吨)客车'
            },
            {
              switch:false,
              title:'货车(10吨以上)重型货车'
            },
            {
              switch:false,
              title:'半挂(15吨以上)两轮'
            },
            {
              switch:false,
              title:'两轮、三轮机动车'
            },
          ]
        },
        {
          switch:false,
          title:'吊车',
          data:[
            {
              switch:false,
              title:'吊车15吨以下(小型车、小货等)'
            },
            {
              switch:false,
              title:'吊车15-25吨(中、重型货车等)'
            },
            {
              switch:false,
              title:'吊车25-50吨(重型货车、半挂等)'
            },
            {
              switch:false,
              title:'吊车50吨以上'
            },
          ]
        },
      ],

      checked:false,
      checked1:false,
      checked2:false,
      input:'',
      input1:'',
      input2:'',

    }
  },

  mounted() {
    if (this.data) {
      console.log(this.data)

    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getCity();
  },
  methods: {
    // 获取城市列表
    getCity(){
      taximeterget_city().then(res => {
        console.log(res);
        this.city = res.data;
      })
    },
    //城市全选
    handleCheckAllChange(val) {
      if (this.selectAll) {
        this.$refs.tree.setCheckedNodes(this.city);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    //获取选择的区域
    handleCheckChange(){
      //console.log(this.$refs.tree.getCheckedNodes());  // 这是选中节点数组
      this.selectList = this.$refs.tree.getCheckedNodes();
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){

          }else {

          }
        } else {
          return false;
        }
      });
    },


    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //部门分配订单比例删除
    del(item,index){
      console.log('点击了删除')
      this.treeList.splice(index,1);
    },

    handleChange(value) {
      console.log(value);
    }


  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.divkuai{
  display: flex;
  align-items: center;
}
.margin-l{
  margin-left: 15px;
}

.left-l{
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity_b{
  margin-top: 10px;
}

.pricing{
  margin: 15px;
}
.pricingRules{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.pricingRules_t_bg{
  padding: 10px 15px 10px 15px;
  background: #f0f1f3;
  border: 1px solid #dfe2e7;
  font-size: 16px;
  font-weight: 500;
  color: #242832;
}

.font1{
  vertical-align: middle; margin-left: 10px;
}
.padding-bianju{
  padding: 20px;
}
.margin-bianju{
  margin-top: 10px;
  margin-bottom: 10px;
}

.fenpeistyle{
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}

.fenpeistyle-s{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.fenpeistyle-s-input{
  width: 300px;
  margin-left: 20px;
}

</style>
